<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">System Setup</h1>
                </v-col>
            </v-row>
            <!-- TODO: loginfront setup ONLY; after that admin can login and do rest of setup -->
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">EtherLink</p>
                    <p>We use EtherLink to verify a customer's email address when they sign up or change their email address.</p>
                    <p class="text-overline mb-0">LoginFront</p>
                    <p>We use EtherLink to authenticate a customer when they sign in.</p>
                    <p class="text-overline mb-0">Unicorn Springs</p>
                    <p>We use EtherLink to manage customer accounts, billing, and payments.</p>
                    <p class="text-overline mb-0">Emplus</p>
                    <p>We use Emplus to send transactional emails to customers.</p>
                    <!-- <p class="mb-0 pb-0">
                        <span>{{ registrationModeText }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditRegistrationMode = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p> -->
                    <!-- <v-dialog v-model="dialogEditRegistrationMode" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Edit registration mode</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>There are 4 modes available:</p>
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Open registration</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up to create new accounts and manage realms within their accounts. This is a good setting for a production SaaS deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Approval required</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up, but all requests are placed in a queue to be approved by an administrator. The accounts are not created without approval. This is a good setting for a "public beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>By invitation only</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed and people can only create accounts with an invitation. Only service administrators can send invitations. This is a good setting for a "private alpha" or "private beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Closed</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed. Service administrators can still create new accounts and realms directly, but new administrator accounts cannot be created. This is the default setting.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-form @submit="editRegistrationMode" onSubmit="return false;" @keyup.enter.native="editRegistrationMode" class="mt-5">
                                    <v-select
                                        v-model="editableRegistrationMode"
                                        :items="registrationModeChoices"
                                        label="Registration mode"
                                        outlined>
                                    </v-select>
                                    <v-card-actions>
                                        <v-row justify="center">
                                        </v-row>
                                    </v-card-actions>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="purple white--text" @click="editRegistrationMode" :disabled="!isEditRegistrationModeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditRegistrationMode = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog> -->
                    <!-- <p class="text-overline mb-0 mt-8">Registration</p>
                    <p class="mb-0 pb-0">
                        <router-link>Allow open registration</router-link>
                    </p> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
// import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';

/**
 * This is a special page for administrators to grant themselves the
 * service admin role. First, the administrator must create for themselves
 * a user in Unicorn Springs and login. Second, the administrator must
 * create the service setup interaction using the `brandprofile admin link`
 * command. The administrator will be redirected to this page with the
 * `i` query parameter value being the interaction id of the `service_setup`
 * interaction, for which the admin's session is authorized when the
 * admin follows that link.
 */

export default {
    data: () => ({
        isViewReady: false,
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        async editServiceConfig(name, content) {
            try {
                this.$store.commit('loading', { editServiceConfig: true });
                const response = await this.$client.main().system.editSetting({ name, content });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Saved changes' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                }
                return response?.isEdited ?? false;
            } catch (err) {
                console.error('failed to edit setting', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                return false;
            } finally {
                this.$store.commit('loading', { editServiceConfig: false });
                this.isViewReady = true;
            }
        },
        // async editRegistrationMode() {
        //     const isEdited = await this.editServiceConfig('registration_mode', this.editableRegistrationMode);
        //     if (isEdited) {
        //         this.registrationMode = this.editableRegistrationMode;
        //     }
        //     this.dialogEditRegistrationMode = false;
        // },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().system.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    // this.registrationMode = settingsMap.registration_mode ?? 'closed';
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.loadServiceSettingsList();
    },
};
</script>
